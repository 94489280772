import React from "react"
import { StaticQuery, graphql } from "gatsby"
import CustomLink from '../custom-link'

const Notification = () => (
  <StaticQuery
    query={graphql`
      {
        strapi {
          notification {
            text
            status
            button {
              id
              newTab
              text
              type
              url
            }
          }
        }
      }
    `}
    render={ data => {
      
      const {text, status, button} = data.strapi.notification
      if (status) {
        return (
          <>
          <div className={`notification`}>
          <div className="notification__container">
          <p className="notification__text">{text}</p>
          <CustomLink className={`notification__button button-${button.type} button-small`} key={ button.id } link={ button }>{ button.text }</CustomLink>
          </div>
          </div>
          <div className="notification__spacer"></div>
          </>
        )
      } else {
        return;
      }
      }
    }
  />
)

export default Notification