import React from "react"
import Layout from "../components/layout"
import "../styles/app.scss"
import { graphql } from "gatsby"

const StyleGuide = ({ data }) => {

  return (
    <>
      <Layout>
      <div className="container" style={{'minHeight': '90vh', 'marginTop': '200px'}}>
        <h1>Lorem333 ipsum dolor.</h1>
        <h2>Lorem ipsum dolor.</h2>
        <h3>Lorem ipsum dolor.</h3>
        <h4>Lorem ipsum dolor.</h4>
        <h5>Lorem ipsum dolor.</h5>
        <p>Lorem ipsum dolor.</p>
        <p className="ps">Lorem ipsum dolor.</p>
        <p className="pxs">Lorem ipsum dolor.</p>
        <br/>
        <br/>

        <br/>
        <ul>
          <li>Lorem</li>
          <li>Ipsum</li>
          <li>Dolor</li>
          <li>Sit Amet</li>
        </ul>
        
        <br/>
        <br/>
        
        <ol>
          <li>Lorem</li>
          <li>Ipsum</li>
          <li>Dolor</li>
          <li>Sit Amet</li>
        </ol>
        <br/>
        <br/>
        <button className="button-primary">Primary</button>
        <br/>
        <br/>
        
        <button className="button-secondary">Secondary</button>
        <br/>
        <br/>
        
        <button className="button-tertiary">Tertiary</button>
        
        <br/>
        <br/>
        <a href="#" className="button-primary">Primary</a>
        
        <br/>
        <br/>
        
        <a href="#" className="button-secondary">Secondary</a>
        <br/>
        <br/>
        
        <a href="#" className="button-tertiary">Tertiary</a>
        <br/>
        <br/>
        <label className="input-text-label">First Name</label>
        <input className="input-text" type="text" placeholder="First Name"/>
        <br/>
        <br/>
        <label className="input-text-label">Message</label>
        
        <textarea className="input-textarea" placeholder="Message"></textarea>
        <br/>
        <br/>
        <label className="input-text-label">Message</label>
        <select className="input-select" placeholder="Message">
          <option>One</option>
          <option>Two</option>
          <option>Three</option>
        </select>
        <br/>
        <br/>
        <label className="input-text-label checkbox">
          <input type="checkbox" title="check" className="checkbox__input" />
          <div className="checkbox__control"></div>
          <div className="checkbox__label">Checkbox</div>
        </label>
        <br/>
        <br/>
        
        <label className="input-text-label radio">
          <input type="radio" name="radio" className="radio__input" />
          <div className="radio__control"></div>
          <div className="radio__label">Radio 1</div>
        </label>
        <label className="input-text-label radio">
          <input type="radio" name="radio" className="radio__input" />
          <div className="radio__control"></div>
          <div className="radio__label">Radio 2</div>
        </label>
        <label className="input-text-label radio">
          <input type="radio" name="radio" className="radio__input" />
          <div className="radio__control"></div>
          <div className="radio__label">Radio 3</div>
        </label>
        <br/>
        <br/>
        <br/>
        <div className="color primaryLight"></div>
        <div className="color primary"></div>
        <div className="color primaryDark"></div>
        <br/>
        
        <div className="color secondaryLight"></div>
        <div className="color secondary"></div>
        <div className="color secondaryDark"></div>
        <br/>
        
        <div className="color tertiaryLight"></div>
        <div className="color tertiary"></div>
        <div className="color tertiaryDark"></div>
        <br/>
        
        <div className="color successLight"></div>
        <div className="color success"></div>
        <div className="color successDark"></div>
        <br/>
        
        <div className="color warningLight"></div>
        <div className="color warning"></div>
        <div className="color warningDark"></div>
        <br/>
        
        <div className="color infoLight"></div>
        <div className="color info"></div>
        <div className="color infoDark"></div>
        <br/>
        
        <div className="color errorLight"></div>
        <div className="color error"></div>
        <div className="color errorDark"></div>
        </div>
      </Layout>
    </>
  )
}

export default StyleGuide