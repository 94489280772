import React from "react"
import { Link } from "gatsby"

const CustomLink = ({ link, children, className }) => {
  const isInternalLink = link.url.startsWith("/");

  if (isInternalLink) {
    return <Link className={className} to={link.url}>{children}</Link>
  }

  return (
    <a
      className={className}
      href={link.url}
      target={link.newTab ? "_blank" : "_self"}
      rel="noreferrer"
    >
      {children}
    </a>
  )
}

export default CustomLink
