import React from "react"
import { StaticQuery, graphql } from "gatsby"
import CustomLink from '../custom-link'
import Image from "../image";

const Footer = () => (
  <StaticQuery
    query={graphql`
      {
        strapi {
          footer {
            linkList {
              links {
                id
                newTab
                text
                url
              }
              id
              title
            }
            logo {
              alternativeText
              mime
              url
              id
            }
            privacyLinks {
              id
              newTab
              text
              url
            }
            socialLinks {
              id
              url
              image {
                alternativeText
                id
                url
                mime
              }
            }
            copyright
          }
        }
      }
    `}
    render={ data => {
      const { linkList, logo, privacyLinks, socialLinks, copyright } = data.strapi.footer;

      const renderFirstNavItem = (link) => {
        if (link.url === '#') {
           return (
            <a className="footer__list-link">{ link.text }</a>
          )
        }
        else {
          return (
            <CustomLink className="footer__list-link" key={ link.id } link={ link }>{ link.text }</CustomLink>
          )
        }
      }

      const renderNavSubItems = (links) => {
        return links.links.map( (link, i) => {
          if (i === 0) {
            return
          }

           if (link.url === '#') {
             return (
               <p className="footer__sub-list-link">{link.text}</p>
             )
           } else {
             return (
               <CustomLink className="footer__sub-list-link" key={link.id} link={link}>{link.text}</CustomLink>
             )
           }
         })
      }


      return (
          <nav className="footer">
            <div className="container-wide footer__container">
            <div className="footer__logo-container">
              <Image media={logo} className="footer__logo" />
            </div>
              <div className="footer__list-container">
                { linkList.map( links => (

                  <div className="footer__list-item">
                      { renderFirstNavItem(links.links[0]) }
                    <div className="footer__sub-list">
                      { renderNavSubItems(links) }
                    </div>
                  </div>
                ))}
              </div>

              <div className="footer__social-container">
              { socialLinks.map( link => (

                <div className="footer__social-item">
                  <CustomLink className="footer__social-item-link" key={link.id} link={link}>
                    <Image media={link.image} className="footer__social-item-logo" />
                  </CustomLink>
                </div>
              ))}
              </div>

              <div className="footer__privacy-container">
                { privacyLinks.map( link => (

                  <div className="footer__privacy-item">
                    <CustomLink className="footer__privacy-item-link" key={link.id} link={link}>{link.text}</CustomLink>
                  </div>
                ))}
              </div>
              <p className='footer__copyright'>{copyright}</p>
            </div>
          </nav>
        )
      }
    }
  />
)

export default Footer
