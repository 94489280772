import React, { useState } from "react"
import Notification from "./notification/notification"
import Navigation from "./navigation/navigation"
import Footer from "./footer/footer"

const Layout = ({ children }) => {

  return (
    <div>
      <Notification />
      <Navigation/>
      <div>{children}</div>
      <Footer/>
    </div>
  )
}

export default Layout
