import React, { useState }  from "react"
import { StaticQuery, graphql } from "gatsby"
import CustomLink from '../custom-link'
import Image from "../image";

const Navigation = () => {
  const [navOpen, setNavOpen] = useState(false);

  return (
  <StaticQuery
    query={graphql`
      {
        strapi {
          navigation {
            linkList {
              links {
                id
                newTab
                text
                url
              }
              id
              title
            }
            logo {
              alternativeText
              mime
              url
              id
            }
            buttons {
              id
              newTab
              text
              type
              url
            }
          }
        }
      }
    `}
    render={ data => {
      const { linkList, logo, buttons } = data.strapi.navigation;

      const renderFirstNavItem = (link) => {
        if (link.url === '#') {
           return (
            <a className="navigation__list-link">{ link.text }</a>
          )
        }
        else {
          return (
            <CustomLink className="navigation__list-link" key={ link.id } link={ link }>{ link.text }</CustomLink>
          )
        }
      }

      const renderNavSubItems = (links) => {
        return links.links.map( (link, i) => {
          if (i === 0) {
            return
          }

           if (link.url === '#') {
             return (
               <p className="navigation__sub-list-link">{link.text}</p>
             )
           } else {
             return (
               <CustomLink className="navigation__sub-list-link" key={link.id} link={link}>{link.text}</CustomLink>
             )
           }
         })
      }

      const renderMobileFirstNavItem = (link) => {
        if (link.url === '#') {
           return (
            <a className="navigation__mobile-list-link">{ link.text }</a>
          )
        }
        else {
          return (
            <CustomLink className="navigation__mobile-list-link" key={ link.id } link={ link }>{ link.text }</CustomLink>
          )
        }
      }

      const renderMobileNavSubItems = (links) => {
        return links.links.map( (link, i) => {
          if (i === 0) {
            return
          }

           if (link.url === '#') {
             return (
               <p className="navigation__mobile-sub-list-link">{link.text}</p>
             )
           } else {
             return (
               <CustomLink className="navigation__mobile-sub-list-link" key={link.id} link={link}>{link.text}</CustomLink>
             )
           }
         })
      }

      return (
        <>
          <nav className={`navigation ${navOpen ? 'nav-open' : null}`}>
            <div className="container-wide navigation__container">
            <CustomLink key={`nav-logo-link`} link={{url: '/', newTab: false}}>
              <Image media={logo} className="navigation__logo" />
            </CustomLink>

            <div className="navigation__list-container">
              { linkList.map( links => (

                <div className="navigation__list-item">
                    { renderFirstNavItem(links.links[0]) }
                  <div className="navigation__sub-list">
                    { renderNavSubItems(links) }
                  </div>
                </div>
              ))}

              { buttons.map( button => (
                <CustomLink className={`navigation__sub-list-button button-${button.type} button-small`} key={button.id} link={button}>{button.text}</CustomLink>
              ))
              }

              </div>

              <div className="navigation__mobile-list-container">


                { buttons.map( button => (
                  <CustomLink className={`navigation__mobile-sub-list-button button-${button.type} button-small`} key={button.id} link={button}>{button.text}</CustomLink>
                  ))
                }

                { linkList.map( links => (

                  <div className="navigation__mobile-list-item">
                      { renderMobileFirstNavItem(links.links[0]) }
                    <div className="navigation__mobile-sub-list">
                      { renderMobileNavSubItems(links) }
                    </div>
                  </div>
                ))}

                <span className="navigation__close" onClick={()=>setNavOpen(!navOpen)}>
                  <span className="navigation__close-x">×</span>
                </span>
                </div>

                <span className="navigation__hamburger" onClick={()=>setNavOpen(!navOpen)}>
                  <span className="navigation__hamburger-1"></span>
                  <span className="navigation__hamburger-2"></span>
                  <span className="navigation__hamburger-3"></span>
                </span>
            </div>

          </nav>
          <div className="navigation__spacer"></div>
          </>
        )
      }
    }
  />
)}

export default Navigation
